import React, { useState, useCallback, memo, useEffect } from 'react';
import { InputGroup, Textarea, InputRightElement, IconButton, Flex, useToast } from "@chakra-ui/react";
import { FaPaperPlane, FaStop } from "react-icons/fa";
import ResizeTextarea from "react-textarea-autosize";
import fetchStopMessage from '../helpers/fetchStopMessage';
import { ChatInterface, MessagesInterface } from '../configs';
import debounce from 'lodash/debounce';

interface DialogueProps {
    dialogueBox: string;
    handleDialogueBoxChange: (value: string) => void;
    sendMessage: () => void;
    waitAnswer: boolean;
    allChats: {[key:string]: ChatInterface};
    currentChat: string;
    isDesktop: boolean|undefined;
    apiAddress: string;
    chatMessages: MessagesInterface[];
}

const DialogueInput = memo(function DialogueInput({
    dialogueBox,
    handleDialogueBoxChange,
    sendMessage,
    waitAnswer,
    allChats,
    currentChat,
    isDesktop,
    apiAddress,
    chatMessages,
}: DialogueProps) {
    const toast = useToast();
    const [localDialogueBox, setLocalDialogueBox] = useState(dialogueBox);

    useEffect(() => {
        setLocalDialogueBox(dialogueBox);
      }, [dialogueBox]);

    const debouncedHandleChange = useCallback(
        debounce((value: string) => {
            handleDialogueBoxChange(value);
        }, 300),
        [handleDialogueBoxChange]
    );

    const handleLocalChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setLocalDialogueBox(value);
        debouncedHandleChange(value);
    }, [debouncedHandleChange]);

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey && !waitAnswer) {
            e.preventDefault();
            sendMessage();
        }
    }, [sendMessage, waitAnswer]);

    const handleStopMessage = useCallback(() => {
        fetchStopMessage(currentChat, apiAddress);
        toast({
            position: 'top',
            title: "Cancelling message...",
            status: "info",
            duration: 6000
        });
    }, [currentChat, apiAddress, toast]);

    return (
        <InputGroup w="67%" size='md'>
            <Textarea 
                isDisabled={waitAnswer}
                placeholder="Type your question for ChatSOC here..." 
                padding="2%" 
                size="large" 
                paddingRight="5rem" 
                borderRadius="10px" 
                rows={1} 
                boxShadow="md" 
                border="1.5px solid #edecec" 
                value={localDialogueBox} 
                onChange={handleLocalChange} 
                onKeyDown={handleKeyDown}
                color="blackAlpha.800" 
                as={ResizeTextarea} 
                maxRows={4} 
                id="dialogue-box"
            />
            <InputRightElement width={isDesktop ? '7rem' : '5rem'} height="100%">
                {allChats[currentChat] && allChats[currentChat].status && allChats[currentChat].status !== 'completed'
                ?
                <IconButton
                    marginLeft="20%"
                    isDisabled={!Object.keys(allChats[currentChat]).includes('status')}
                    aria-label="Stop"
                    size={isDesktop ? "md" : "sm"}
                    colorScheme={localDialogueBox.length === 0 ? 'gray' : "blackAlpha"}
                    borderRadius="10px" 
                    icon={<FaStop color={localDialogueBox.length === 0 ? "#b4b3b3" : "white"} />}
                    onClick={handleStopMessage}
                />
                :
                <Flex align="center">
                    <IconButton
                        marginLeft="40%"
                        aria-label="Send Message"
                        size={isDesktop ? "md" : "sm"}
                        isLoading={waitAnswer}
                        colorScheme={localDialogueBox.length === 0 ? 'gray' : "blackAlpha"}
                        icon={<FaPaperPlane color={localDialogueBox.length === 0 ? "#b4b3b3" : "white"} />}
                        onClick={sendMessage}
                        id={`ChatSOC - Chat Submission ${Math.floor(chatMessages.length/2) + 1}`}
                    />
                </Flex>}
            </InputRightElement>
        </InputGroup>
    );
});

export default DialogueInput;
import {
    Box,
    Flex,
    Input,
    Center,
    Text,
    Spacer,
    Button,
    InputGroup,
    InputRightElement,
    MenuList,
    useToast,
    MenuItem,
    IconButton,
    Tooltip,
    useBreakpointValue,
    MenuButton,
    Menu,
    Avatar,
    FormControl,
    FormLabel,
    Switch,
    Spinner,
    CloseButton,
    Image,
    Select,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef, useCallback, useMemo  } from "react";
import { BsLayoutSidebarInset } from "react-icons/bs";
import {  FaClipboard, FaEllipsisV, FaBars, FaRegFile } from "react-icons/fa";
import { db, auth } from "../firebase";
import { onValue, ref, remove, update, query, orderByChild, equalTo, Unsubscribe } from "firebase/database";
import fetchSendMessage from "../helpers/fetchSendMessage"
import fetchCreateChat from "../helpers/fetchCreateChat"
import UsersModal from "../components/UsersModal"
import { usePDF } from 'react-to-pdf';
import {useDropzone} from 'react-dropzone'
import fetchUploadOpenaiFiles from "../helpers/fetchUploadOpenaiFiles";
import fetchGetUrls from "../helpers/fetchGetUrls";
import fetchSearch from "../helpers/fetchSearch";
import ChatMessages from "../components/ChatMessages";
import Sidebar from "../components/Siderbar";
import DialogueInput from "../components/DialogueInput";
import { ChatInterface } from "../configs";


const startButtons = [
    {
        "title": "Get answers to your questions about California workers' compensation law",
        "question": "When does the clock start to accept or deny a claim?"
    },
    {
        "title": "Draft text for a memo, email, or letter",
        "question": "Prepare a memo explaining the time limit for bringing an action for penalties under LC 5814."
    },
    {
        "title": "Request bullet points for a PowerPoint presentation on a topic",
        "question": "Prepare bullet points for a PowerPoint presentation on affirmative defenses."
    },
    {
        "title": "Use it for quick lookups",
        "question": "What is the maximum TTD rate for a 2020 DOI?"
    },
    {
        "title": "Get explanations of medical concepts related to workers compensation",
        "question": "What are the typical physical limitations associated with spondylolisthesis?"
    },
    {
        "title": "Create sample deposition questions",
        "question": "Give me deposition questions to ascertain whether the intoxication defense applies to a slip and fall injury at the annual holiday party."
    },
    {
        "title": "Ask hypothetical questions to learn what factors apply",
        "question": "If someone is injured at a party was sponsored by work but not required to attend, would it be a covered work injury?",
    },
    {
        "title": "Get a summary of cases that speak to a particular issue",
        "question": "List and summarize the key cases that address apportionment to prior nonindustrial injury or condition."
    }
]


export default function ChatPage(){
    const [dialogueBox, setDialogueBox] = useState<string>('');
    const [currentChat, setCurrentChat] = useState<string>('');
    const [waitAnswer, setWaitAnswer] = useState<boolean>(false);
    const [newDialogueReady, setNewDialogueReady] = useState<boolean>(false);
    const [allChats, setAllChats] = useState<any>({});
    const [chatMessages, setChatMessages] = useState<any[]>([]);
    const [showBar, setShowBar] = useState<boolean>(true);
    const [windowAddUser, setWindowAddUser] = useState<boolean>(false);
    const [authorizedUsers, setAuthorizedUsers] = useState<string[]>([]);
    const [allSharedChats, setAllSharedChats] = useState<string[]>([]);
    const [dialogueFiles, setDialogueFiles] = useState<File[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [apiAddress, setApiAddress] = useState<string>('https://chatsocapi.sullivanoncomp.com');
    const [matchedChats, setMatchedChats] = useState<string[]>([]);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [provider, setProvider] = useState<string>('anthropic');
    const [sentTime, setSentTime] = useState<number[]>([]);
    const isDesktop = useBreakpointValue<boolean>({base: false, md:true});
    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const toast = useToast();
    const navigate = useNavigate();
    const { toPDF, targetRef } = usePDF({filename: 'chatsoc_conv.pdf'});
    
    const acceptedFormats = [
        ".doc",
        ".docx",
        ".html",
        ".json",
        ".pdf",
        ".md",
        ".txt",
        ".pptx",
    ]

    const onDrop = useCallback((acceptedFiles:any) => {
        // Loop over files
        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
          
            // Extract the file extension
            const fileExtension = file.path.split('.').pop().toLowerCase();
          
            if (!acceptedFormats.includes(`.${fileExtension}`)) {
              // Early return to stop further processing of this file
              return toast({
                position: 'top',
                title: 'Invalid file format',
                description: "Please upload a file with one of the following formats: .doc, .docx, .html, .json, .md, .pdf, .pptx, .txt",
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }          
          }
        setDialogueFiles((prev) => [...prev, ...acceptedFiles]);
        toast({
            position: 'top',
            title: 'Document(s) added to conversation',
            description: "Click the document icon to remove the file",
            status: 'info',
            duration: 5000,
            isClosable: true,
          
        })
      }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})

    const handleDialogueBoxChange = useCallback((value: string) => {
        setDialogueBox(value);
      }, []);

    useEffect(() => {
        if (searchText.length > 0) {
          setMatchedChats(allChats ? Object.keys(allChats).filter((x) => allChats[x].title.toLowerCase().includes(searchText.toLowerCase())) : []);
          setLoadingSearch(true);
          const timeoutId = setTimeout(() => {
            fetchSearch(searchText, auth!.currentUser!.uid, apiAddress).then((x) => {
              setMatchedChats(x);
              setLoadingSearch(false);
            });
          }, 500);
          return () => clearTimeout(timeoutId);
        } else {
          setMatchedChats([]);
        }
      }, [searchText, allChats, apiAddress, auth.currentUser]);


    useEffect(() => {
        if (newDialogueReady && currentChat) {
            sendMessage();
            setNewDialogueReady(false);
        }
    }, [newDialogueReady, currentChat]);

    useEffect(() => {
        if (currentChat) {
            navigate(`/chat?id=${currentChat}`);
            const chatMessagesRef = ref(db, 'rawChatMessages/' + currentChat);
            const unsubscribeChatMessages = onValue(chatMessagesRef, (snapshot) => {
                if (snapshot.val()) {
                    const data = snapshot.val();
                    setChatMessages(data);
                } else {
                    setChatMessages([]);
                }
            });
            return () => {
                unsubscribeChatMessages();
            };
        } else {
            navigate('/chat');
    
            // No subscription in this branch, so no cleanup function is needed
        }
        setProvider('anthropic');
    }, [currentChat]);
    

    useEffect(() => {
        let unsubscribeFunctions:Unsubscribe[] = [];
    
        if (auth.currentUser) {
            const previousChatsQuery = query(ref(db, 'chats/'), orderByChild("userId"), equalTo(auth.currentUser.uid));
            const unsubscribePreviousChats = onValue(previousChatsQuery, (snapshot) => {
                if (snapshot.exists()) {
                    let data = snapshot.val() as Record<string, ChatInterface>;
                    const sortedEntries = Object.entries(data)
                        .sort(([, a], [, b]) => (b.lastMessage || b.time) - (a.lastMessage || a.time)) as [string, ChatInterface][];
                    const sortedChats: Record<string, ChatInterface> = Object.fromEntries(sortedEntries);
                    setAllChats(sortedChats);
                } else {
                    setAllChats({});
                }
            });
            unsubscribeFunctions.push(unsubscribePreviousChats);
        }
    
        const allSharedChatsRef = ref(db, 'publicChats');
        const unsubscribeAllSharedChats = onValue(allSharedChatsRef, (snapshot) => {
            if (snapshot.exists()) {
                setAllSharedChats(Object.keys(snapshot.val()));
            }
        });
        unsubscribeFunctions.push(unsubscribeAllSharedChats);
    
        const usersRef = ref(db, 'allowedUsers');
        const unsubscribeUsers = onValue(usersRef, (snapshot) => {
            if (snapshot.exists()) {
                setAuthorizedUsers(Object.keys(snapshot.val()));
            }
        });
        unsubscribeFunctions.push(unsubscribeUsers);
    
        // Cleanup function
        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, []);
    

    useEffect(() => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        if (chatMessages && chatMessages.length > 0 && chatMessages[chatMessages.length -1].role === 'assistant') {
            setWaitAnswer(false);
        }
    }, [chatMessages.length])

    const startNewDialogue = async () => {
        if (allChats && allChats[Object.keys(allChats)[0]] && allChats[Object.keys(allChats)[0]].title === 'New Chat') {
            setCurrentChat(Object.keys(allChats)[0]);
        } else {
            fetchCreateChat(auth.currentUser!.uid, apiAddress)
            .then((x:any) => {
                setCurrentChat(x.chat_id)
            })
        }
      };

    const sendMessage = useCallback(async () => {
        if (dialogueBox.length === 0) {
          return;
        }
        const lastMinute = sentTime.filter((x) => x > Date.now() - 60000);
        // If more than 5 messages have been sent in the last minute, don't send the message
        if (lastMinute.length > 5) {
            toast({
                position: 'top',
                title: 'Too many messages sent',
                description: "Please wait a minute before sending more messages.",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            return;
        }
        setSentTime((prev) => [...prev, Date.now()]);
        setWaitAnswer(true);
        if (!currentChat) {
          await startNewDialogue();
          setNewDialogueReady(true);
          setWaitAnswer(false);
        } else {
          const uploadedFiles = [];
          if (dialogueFiles.length > 0) {
            const uploadPromises = dialogueFiles.map(async (dialogueFile) => {
              const upload = await fetchUploadOpenaiFiles(dialogueFile, apiAddress);
              return { id: upload.id, title: dialogueFile.name };
            });
            const results = await Promise.all(uploadPromises);
            uploadedFiles.push(...results);
          }
          if (dialogueBox.length < 14000) {
            const text = `${dialogueBox}`;
            setDialogueBox('');
            try {
              await fetchSendMessage(text, currentChat, provider, apiAddress);
              setDialogueFiles([]);
              setWaitAnswer(false);
            } catch (error) {
              setWaitAnswer(false);
            }
            update(ref(db, 'chats/' + currentChat), {
              lastMessage: Date.now()
            });
          } else {
            toast({
              position: 'top',
              title: 'The message is too long',
              description: "Please shorten it to less than 14k characters.",
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }
      }, [dialogueBox, currentChat, dialogueFiles, apiAddress, setWaitAnswer, setNewDialogueReady, setDialogueBox, setDialogueFiles, startNewDialogue, toast]);

    function deleteDialogue(){

        remove(ref(db, '/chatMessages/' + currentChat)).then(() => {
            remove(ref(db, '/chats/' +  currentChat)).then(() => {
                setCurrentChat('')
                navigate('/')
            })
        })
    }


    function getLoader(){
        return (
            <Flex marginTop="3%" marginLeft="2%" position="relative" fontFamily={"Bitter, serif"} fontSize="16px" lineHeight="28px">
                {allChats[currentChat].status !== 'failed' && <Spinner thickness="10px" h="53px" w="53px" marginLeft="-.2%" marginTop="-.15%" position="absolute" color={allChats[currentChat].status === 'running' ? "gray" : "orange"} zIndex={1} />}
                <Avatar h="50px" w="50px" src="chat_avatar.png" position="relative" zIndex={2} />
                <Text
                    fontWeight={700}
                    boxShadow="md"
                    maxW="65%"
                    color="gray.700"
                    marginLeft="2%"
                    padding="1.5%"
                    borderRadius="10px"
                    bg="#F7F7F8">
                        {
                        allChats[currentChat].status === 'running' ? 
                        "Evaluating question..." :
                        allChats[currentChat].status === 'retrieving' ? 
                        "Looking for documents in Sullivan on Comp..." :
                        ""}
                </Text>
            </Flex>
        );
    }
    

    function updatePublicChats() {
        if (allSharedChats.includes(currentChat)) {
            remove(ref(db, '/publicChats/' + currentChat))
        } else {
            update(ref(db, 'publicChats'), {
                [currentChat]: true
            })
        }
    }


    function publicChatToClipboard(url:string) { 
        navigator.clipboard.writeText(url)
        toast({
            position: 'top',
            title: 'The URL was copied to your clipboard',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })

    }

    const handleFileChange = (event:any) => {
        if (event.target.files) {
            setDialogueFiles(Array.from(event.target.files));
            toast({
                position: 'top',
                title: 'Document(s) added to conversation',
                description: "Click the paperclip icon to remove them",
                status: 'info',
                duration: 5000,
                isClosable: true,
            })
        }
      };

      const memoizedDialogueInput = useMemo(() => (
        <DialogueInput
          dialogueBox={dialogueBox}
          handleDialogueBoxChange={handleDialogueBoxChange}
          sendMessage={sendMessage}
          chatMessages={chatMessages}
          waitAnswer={waitAnswer}
          isDesktop={isDesktop}
          apiAddress={apiAddress}
          allChats={allChats}
          currentChat={currentChat}
        />
      ), [dialogueBox, handleDialogueBoxChange, sendMessage, chatMessages, waitAnswer, isDesktop, apiAddress, allChats, currentChat]);


    return(
    <Flex h="100vh" w="100vw">
        {showBar &&
        <Sidebar
            isDesktop={isDesktop}
            showBar={showBar}
            setShowBar={setShowBar}
            allChats={allChats}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            startNewDialogue={startNewDialogue}
            searchText={searchText}
            setSearchText={setSearchText}
            matchedChats={matchedChats}
            loadingSearch={loadingSearch}
            setApiAddress={setApiAddress}
            apiAddress={apiAddress}
            navigate={navigate}
            />}
        <Box {...getRootProps()} zIndex={1}  w={showBar ? "80%" : "100%"}>
            <input {...getInputProps()} />
            {isDragActive ? <Text top="50%" left="50%" fontSize="40px" fontWeight={300} color="gray" position="fixed">Drop your file here</Text> : null}
            <Box style={{WebkitMaskImage: isDragActive ? "linear-gradient(to bottom, rgba(0,0,0,.4) 0%, rgba(0,0,0,0))" : ""}} zIndex={2} position='fixed' h="40px" bg={!currentChat ? "transparent" : provider === 'anthropic' ? "#f2bdac" : "#f1f1f1"} borderBottom={currentChat ? ".5px solid gray" : ""} w={showBar ? "80%" : "100%"}>
                <Center h="40px">
                    {!showBar && isDesktop && (
                        <Tooltip label="Open sidebar">
                            <IconButton onClick={() => {setShowBar((prev) => !prev); }} variant="ghost" aria-label="Close sidebar" icon={<BsLayoutSidebarInset />} />
                        </Tooltip>
                    )}
                    <Spacer />
                    <Flex marginLeft="3%" w="100%" justify="center">
                        {currentChat && provider === 'openai' && <Image h="25px" src="openai_logo.png" />}
                        {currentChat && provider === 'anthropic' && <Image h="25px" src="claude_logo.png" borderRadius="10px" />}
                        <Text fontFamily={"Elza, sans-serif"} noOfLines={1} marginLeft="1%" fontSize={18} fontWeight={600}>{allChats[currentChat] && allChats[currentChat].title}</Text>
                        </Flex>
                    <Spacer />
                    {currentChat && <Menu size="md" placement="left">
                        <MenuButton zIndex={3} colorScheme="gray" as={IconButton} variant="ghost" icon={<FaEllipsisV />} />
                        <MenuList >
                            <MenuItem closeOnSelect={false}>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel fontWeight={700} color="#515151" mb='0'>
                                        Share it publicly
                                    </FormLabel>
                                    <Spacer />
                                    <Switch id="ChatSOC - Enable Shared Publicly" isChecked={allSharedChats && allSharedChats.includes(currentChat)} onChange={(e) => {updatePublicChats()}} colorScheme="green" />
                                </FormControl>
                            </MenuItem>
                            {allSharedChats && allSharedChats.includes(currentChat) && (
                            <InputGroup>
                                <Input isDisabled={true} value={`${process.env.REACT_APP_CURRENT_ENV === 'staging' ? 'https://staging-chatsocwebapp.sullivanoncomp.com' : 'https://chatsocwebapp.sullivanoncomp.com'}/chatHistory?chatId=${currentChat}`}  />
                                <InputRightElement>
                                    <Tooltip label="Copy to clipboard">
                                        <IconButton
                                            id="ChatSOC - Copy Public Share Link"
                                            variant="ghost"
                                            aria-label="Clipboard"
                                            icon={<FaClipboard style={{ pointerEvents: 'none' }} />}
                                            color='gray.500'
                                            onClick={() => {publicChatToClipboard(`${process.env.REACT_APP_CURRENT_ENV === 'staging' ? 'https://staging-chatsocwebapp.sullivanoncomp.com' : 'https://chatsocwebapp.sullivanoncomp.com'}/chatHistory?chatId=${currentChat}`)}}
                                        />
                                    </Tooltip>
                                </InputRightElement>
                            </InputGroup>
                            )}
                            {
                            //<MenuItem onClick={() => {downloadChat(); }} fontWeight={700} color="#515151">Download</MenuItem>
                            }
                            <MenuItem closeOnSelect={false} fontWeight={700} color="#515151">
                                <Box w="100%">
                                    Base Model
                                    <Select value={provider} onChange={(e) => {setProvider(e.target.value)}} onClick={(e) => {e.stopPropagation(); e.preventDefault()}}>
                                        <option value="anthropic">Claude</option>
                                        <option value="openai">OpenAI</option>
                                    </Select>
                                </Box>
                            </MenuItem>
                            <MenuItem fontWeight={700} color="#515151" onClick={() => {toPDF();}}>Save as PDF</MenuItem>
                            <MenuItem fontWeight={700} color="#515151" onClick={() => { deleteDialogue(); }}>Delete</MenuItem>
                        </MenuList>
                    </Menu>}
                    {currentChat && <CloseButton onClick={() => {setCurrentChat(''); setDialogueBox("")}} />}
                    {!isDesktop && <IconButton onClick={() => {setShowBar(!showBar);}} aria-label="Bars" variant="ghost" color="black" icon={<FaBars />} /> }
                </Center>
            </Box>
            <Box style={{WebkitMaskImage: isDragActive ? "linear-gradient(to bottom, rgba(0,0,0,.4) 0%, rgba(0,0,0,0))" : "linear-gradient(to bottom, rgba(0,0,0,1) 95%, rgba(0,0,0,0))"}} fontFamily={'"Elza", sans-serif'} h="80%" overflowY="auto" bg="#ffffff">
                {!currentChat ?
                <Box marginTop="4%">
                    <Center marginBottom="4%" display="block" >
                        <Image src="./chatsoc_logo.svg" w="260px" marginLeft="auto" marginRight="auto" marginBottom="20px" />
                        <Text color="#17404e" textAlign="center" paddingLeft="2%" paddingRight="2%" fontWeight={600}>Submit your question in the chat box below to get an AI response informed by the content in Sullivan on Comp</Text>
                    </Center>
                    {isDesktop && <Box>
                        <Center marginTop="-4%">
                            <Box w="70%">
                            <Center marginTop="4%" padding="2%">
                                <Flex direction={{ base: "column", md: "row" }} gap="4%" width="100%" justify="space-between" wrap="wrap">
                                    {startButtons.map((x, idx) => {
                                        return (
                                            <Button
                                            id="ChatSOC - New Chat Created"
                                            onClick={() => {startNewDialogue().then(() => {setDialogueBox(x.question)});}}
                                            marginBottom="2%"
                                            boxShadow="base"
                                            w="48%"
                                            textAlign="left"
                                            variant="outline"
                                            padding="20px"
                                            paddingLeft="2%"
                                            paddingRight="2%"
                                            height="auto"
                                            overflow="hidden"
                                            key={idx}
                                            >
                                                <Box w="100%">
                                                    <Text color="#17404e" fontSize="14px" fontWeight={600} overflow="hidden" whiteSpace="normal">
                                                        {x.title}
                                                    </Text>
                                                    <Text marginTop="1%" fontWeight={300} fontSize="12px" color="gray" overflow="hidden" whiteSpace="normal">
                                                        {x.question}
                                                    </Text>
                                                </Box>
                                            </Button>
                                        )
                                    }
                                    )}                                 
                                </Flex>
                                </Center>

                            </Box>
                        </Center>
                    </Box>}
                </Box>
                :
                <ChatMessages
                    status={allChats[currentChat] && allChats[currentChat].status}
                    targetRef={targetRef}
                    chatMessages={chatMessages}
                    currentChat={currentChat}
                    allReferences={allChats && allChats[currentChat] && allChats[currentChat].references || []}
                    apiAddress={apiAddress}
                />
                }
                {allChats[currentChat] &&
                allChats[currentChat].status &&
                !["writing", "completed", "canceled"].includes(allChats[currentChat].status) &&
                getLoader()}
          <div style={{ height: "30px" }} ref={scrollableDivRef} />
            </Box>
            <Center h="15%">
                {memoizedDialogueInput}
            </Center>
        </Box>
      <UsersModal windowAddUser={windowAddUser} setWindowAddUser={setWindowAddUser} authorizedUsers={authorizedUsers} />
    </Flex>
    )
}
